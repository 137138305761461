import React, { useEffect, useState } from "react";
import "../components/homeModal.scss";

interface HomeModalProps {
  showHomeModal: boolean;
  setShowHomeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomeModal: React.FC<HomeModalProps> = ({
  showHomeModal,
  setShowHomeModal,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (showHomeModal) {
      setShouldShow(true);
      const timer = setTimeout(() => setIsVisible(true), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
      const timer = setTimeout(() => setShouldShow(false), 300);
      return () => clearTimeout(timer);
    }
  }, [showHomeModal]);

  const GoToWebinar = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.strategix.zone/node/1853845", "_blank");
    }
    setShowHomeModal(false);
  };

  return (
    <>
      {shouldShow && (
        <div className={`home-modal ${isVisible ? "open" : "close"}`}>
          <div className="modal-content">
            <div
              className="close-modal"
              onClick={() => setShowHomeModal(false)}
            >
              X
            </div>
            <div className="banner-bottom">
              <button className="pulse" onClick={GoToWebinar}>
                Register for Webinar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeModal;
